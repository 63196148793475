@import url('https://fonts.cdnfonts.com/css/montserrat');

body {
  font-family: 'Montserrat', serif;
}

.container {
  margin: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
  }

  .site-title {
    display: none;
  }
}

.nav {
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  justify-content: flex-end; 
  width: 80%;
  /* margin-right: 9rem; */
}

.experience-link {
  margin-left: auto;
}

.site-title {
  font-size: 2rem;
  margin-top: 1rem;
  display: flex;
  /* margin-left: 9rem; */
}

@media (max-width: 768px) {
  .site-title {
    display: none;
  }
}

.nav ul li a:hover {
  color: blue;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}





.slide-container {
  position: absolute;
  top: 40%;
  right: 7%;
  transform: translate(0, -50%);
  width: 35%;
  height: auto;
  overflow: hidden;
}

@media (max-width: 768px) {
  .slide-container {
    display: none;
  }
}

.outer-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: white;
  overflow: hidden;
}

.inner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
}

.about-container {
  height: 80%;
}

.inner-container > div {
  margin-bottom: 1rem;
  text-align: left;
  line-height: 1.2;
}

.inner-container > div h2,
.inner-container > div h3,
.inner-container > div h4 {
  margin-bottom: 0.3rem;
  line-height: .5;
}

.inner-container > div h4 {
  color: gray;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 350px;
  width: 100%;
}

.wave-container {
  position: absolute;
  top: 40%;
  left: 10%;
  transform: translateY(-55%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  color: black;
}

.hand-wave {
  font-size: 3rem;
}

.name {
  font-size: 2.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.description {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  color: gray;
  white-space: normal;
  margin-bottom: 2rem;
  overflow-wrap: break-word;
  max-width: 40%;
}

@media (max-width: 1100px) {
  .description {
    overflow-wrap: break-word;
  }
}

.company {
  display: flex;
  align-items: center;
}

.logo {
  height: 3rem;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  margin-bottom: -0.5rem;
}

.logo2 {
  height : 3.5rem;
  margin-left: -0.5rem;
  margin-top: 0.3rem;
  margin-bottom: -0.5rem;
}

.logo3 {
  height : 3.5rem;
  margin-left: -0.5rem;
  margin-top: 0.3rem;
  margin-bottom: -0.75rem;
}

.logo1 {
  height: 2rem;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
}

.link-gray-description {
  color: gray;
  text-decoration: underline;
}

.link-black {
  color: black;
  text-decoration: underline;
}

.link-gray-description:hover,
.link-black:hover {
  color: blue;
  text-decoration: underline;
}

.lined ul {
  line-height: 1.5;
}

.header {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.github-link,
.resume-link,
.linkedin-link {
  margin-right: 1rem;
}

.quote {
  position: absolute;
  bottom: 20%;
  left: 50%; 
  transform: translateX(-50%);
  text-align: center;
  font-size: 1.2rem;
  color: gray;
}

@media (max-width: 768px) {
  .slide-container,
  .quote {
    display: none;
  }
}